import { ModuleWithProviders, NgModule } from "@angular/core";
import { HeroBannerComponent } from '@tytapp/common-ui/hero-banner/hero-banner.component';
import { FeedTagComponent } from "./feed-tag/feed-tag.component";
import { AnnouncementsBlockComponent } from "./announcements-block/announcements-block.component";
import { MobileAdBlockComponent } from "./mobile-ad-block/mobile-ad-block.component";
import { PlusAdBlockComponent } from "./plus-ad-block/plus-ad-block.component";
import { PagerComponent } from "./pager/pager.component";
import { TYTCommonModule } from "@tytapp/common";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NewsletterComponent } from "./newsletter-module/newsletter-module.component";
import { FormsModule } from "@angular/forms";
import { SocialButtonsComponent } from "./social-buttons/social-buttons.component";
import { TuneInComponent } from "./tune-in/tune-in.component";
import { SubNavComponent } from "./sub-nav/sub-nav.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PageApiService } from "./page-api.service";
import { AnnouncementsService } from "./announcements.service";
import { ProductionsService } from "./productions.service";
import { PressArticlesService } from "./press-articles.service";
import { ContentBadgeComponent } from "./content-badge.component";
import { LiveTagComponent } from "./live-tag/live-tag.component";
import { PlusTagComponent } from "./plus-tag/plus-tag.component";
import { TopicSelectorComponent } from "./topic-selector/topic-selector.component";
import { UserModule } from "@tytapp/user";
import { CommentsService } from "./comments.service";
import { PaywallDialogComponent } from "./paywall-dialog/paywall-dialog.component";
import { PaywallComponent } from "./paywall/paywall.component";
import { GoalProgressComponent } from "./goal-progress/goal-progress.component";
import { EmbeddedOfferSetComponent } from "./embedded-offer-set/embedded-offer-set.component";
import { CallToActionComponent } from "./call-to-action/call-to-action.component";
import { BantaSdkModule } from '@banta/sdk';
import { EndorsementItemComponent } from "./endorsement-item/endorsement-item.component";
import { EndorsementsComponent } from "./endorsements/endorsements.component";
import { ExpandBoxComponent } from "./expand-box/expand-box.component";
import { NavAvatarComponent } from "./avatar.component";
import { MatIconModule } from "@angular/material/icon";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { TagComponent } from './tag/tag.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnimatedCarouselComponent } from './animated-carousel/animated-carousel.component';
import { C2aProgressComponent } from './c2a-progress/c2a-progress.component';
import { RegistrationBenefitsComponent } from '@tytapp/common-ui/registration-benefits/registration-benefits.component';
import { ShareaholicService } from '@tytapp/common-ui/shareaholic.service';
import { HoldingPageComponent } from '@tytapp/common-ui/holding-page.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterLinkMarkerDirective } from '@tytapp/common-ui/routerlink-marker.directive';
import { OfflinePageComponent } from '@tytapp/common-ui/offline-page/offline-page.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselItemComponent } from './carousel-item/carousel-item.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { IconViewComponent } from './icon-view.component';
import { JoinButtonComponent } from './join-button.component';
import { TweetComponent } from './tweet.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { PlaybackStatusBarComponent } from './playback-status-bar/playback-status-bar.component';
import { PlaybackSessionsService } from './playback-sessions.service';
import { AcceptTermsComponent } from './accept-terms/accept-terms.component';
import { DeferredComponent } from './deferred.component';
import { AppStoreLinkComponent } from './app-store-link/app-store-link.component';

const COMPONENTS = [
    AcceptTermsComponent,
    TagComponent,
    FeedTagComponent,
    AnnouncementsBlockComponent,
    MobileAdBlockComponent,
    PlusAdBlockComponent,
    PagerComponent,
    NewsletterComponent,
    SocialButtonsComponent,
    TuneInComponent,
    SubNavComponent,
    PageNotFoundComponent,
    PlusTagComponent,
    LiveTagComponent,
    ContentBadgeComponent,
    TopicSelectorComponent,
    HeroBannerComponent,
    PaywallComponent,
    PaywallDialogComponent,
    GoalProgressComponent,
    EmbeddedOfferSetComponent,
    CallToActionComponent,
    EndorsementItemComponent,
    EndorsementsComponent,
    ExpandBoxComponent,
    NavAvatarComponent,
    AnimatedCarouselComponent,
    C2aProgressComponent,
    RegistrationBenefitsComponent,
    HoldingPageComponent,
    OfflinePageComponent,
    RouterLinkMarkerDirective,
    CarouselComponent,
    CarouselItemComponent,
    IconViewComponent,
    JoinButtonComponent,
    DateTimePickerComponent,
    TweetComponent,
    DeferredComponent,
    PlaybackStatusBarComponent,
    AppStoreLinkComponent
];

@NgModule({
    imports: [
        TYTCommonModule,
        FormsModule,
        CommonModule,
        RouterModule,
        UserModule,
        BantaSdkModule,
        MatIconModule,
        MatDatepickerModule,
        MatInputModule,
        MatTooltipModule,
        MatMenuModule,
        MatButtonModule,
        RecaptchaModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})
export class CommonUiModule {
    static forRoot(): ModuleWithProviders<CommonUiModule> {
        return {
            ngModule: CommonUiModule,
            providers: [
                AnnouncementsService,
                PageApiService,
                ProductionsService,
                PressArticlesService,
                ShareaholicService,
                PlaybackSessionsService,
                CommentsService
            ]
        };
    }
}
